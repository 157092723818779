@import url("https://fonts.googleapis.com/css?family=Montserrat|Open+Sans&display=swap");
@mixin shadow($hoffset, $voffset, $blur, $spread, $color) {
    -webkit-box-shadow: $hoffset $voffset $blur $spread $color;
    -moz-box-shadow: $hoffset $voffset $blur $spread $color;
    box-shadow: $hoffset $voffset $blur $spread $color;
}
@mixin noselect {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

$reactiveWidth: 700px;
$deepblue: rgba(20, 70, 234, 1);

body {
    min-height: 100vh;
    margin: 0;
    padding: 0;
}

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: "Open Sans", "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva,
        Verdana, sans-serif;
    background-color: #f8f8f8;
}

a {
    color: #06c;
    text-decoration: none;
}

.ionicon {
    z-index: 1;
}

.anchor {
    display: block;
    position: relative;
    top: -150px;
    visibility: hidden;
}

/* HEADER */

.flex-right {
    justify-content: right;
    margin-left: auto;
}

@keyframes backgroundColorShift {
    0% {
        background-position: 100% 0%;
    }
    100% {
        background-position: 0% 0%;
    }
}

@keyframes contentFade {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
    font-family: "Montserrat";
    font-size: 20px;
    // @include shadow(0px, 0px, 15px, 5px, rgba(0, 0, 0, 0.05));

    #preheader {
        display: flex;
        background: linear-gradient(
            to right,
            $deepblue 0%,
            rgba(3, 220, 236, 1) 33%,
            rgba(3, 220, 236, 1) 36%,
            $deepblue 66.67%,
            rgba(3, 220, 236, 1) 100%
        );
        background-size: 300% 300%;
        padding: 10px;
        color: white;
        animation: backgroundColorShift 30s linear infinite;
        &.shifted {
            // animation: backgroundColorShift 3s cubic-bezier(0.39, 0.06, 0.22, 0.94);
            animation-duration: 2s;
        }

        .ionicon {
            height: 100%;
            min-width: 30px;
            margin-right: 8px;
        }

        div {
            max-width: 60%;
            display: flex;
            align-items: center;
        }

        a {
            font-size: 18px;
            margin: auto;
            color: white;
        }

        .flex-right a {
            font-size: 22px;
            text-align: right;
        }
    }

    #header {
        background-color: rgba(255, 255, 255, 0.85);
        backdrop-filter: blur(20px);
        padding: 8px;
        display: flex;
        align-items: center;

        a {
            padding: 8px;
            color: black;
        }

        #dr {
            margin: 0px;
            font-size: 30px;
            font-weight: 700;
            white-space: nowrap;
        }
    }

    #menuIcon {
        display: none;
        position: relative;
        background: white;
        border: none;
        cursor: pointer;
        width: 100%;
        height: 30px;
        padding: 0 !important;

        .ionicon {
            width: 100%;
            height: 100%;
        }
    }

    nav {
        width: 65%;
        max-width: 800px;
        position: relative;
        display: flex;

        a {
            padding: 8px;
            border-radius: 0.5em;
            transition: all 0.2s ease;
            margin: auto;
            &.selected {
                background-color: #eaeaea;
            }
            &:hover {
                background-color: #dedede;
            }
        }

        .separationDot {
            padding: 8px 0;
            visibility: hidden;
            cursor: default;
            @include noselect();
        }
    }

    @media (max-width: $reactiveWidth) {
        #header {
            padding: 0px;
            padding-top: 12px;
            flex-direction: column;
            transition: all 0.5s ease-in-out;
        }
        nav {
            flex-direction: column;
            margin-left: 0px !important;
            max-height: 0px;
            overflow: hidden;
            transition: all 0.5s ease;

            a {
                margin: 2px auto;
            }

            .separationDot {
                display: none;
            }
        }

        #preheader {
            padding: 6px;
            a {
                font-size: 16px;
            }
        }

        #menuIcon {
            display: block;
        }
    }
}

/* FOOTER */

footer {
    margin-bottom: 0;
    padding: 20px;
    background-color: white;
    flex-shrink: 0;

    #footer {
        display: grid;
        margin: auto;
        max-width: 1000px;
        grid-template-columns: 2fr 1fr 1fr;
    }

    h1,
    h2 {
        color: black;
        font-family: "Montserrat";
    }

    h2 {
        color: $deepblue;
    }

    ul,
    li {
        padding: 0;
        list-style-type: none;
    }

    li a,
    span a {
        color: black;
    }

    span {
        font-size: 18px;
    }

    @media (max-width: $reactiveWidth) {
        #footer {
            grid-template-columns: 1fr;
            grid-template-rows: auto auto auto;
            text-align: center;
        }
    }
}

/* CONTENT */

#content {
    box-sizing: border-box;
    width: 100%;
    max-width: 1000px;
    margin: auto;
    padding-top: 20px;
    padding-bottom: 50px;
    &.faded {
        animation: contentFade 0.6s ease-in-out;
    }

    p {
        font-size: 20px;
    }

    div.paragraph {
        margin: 50px 0 100px 0;
    }

    div.cardContainer {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        row-gap: 50px;
        column-gap: 15px;
    }

    div.card {
        box-sizing: border-box;
        margin: auto;
        height: 300px;
        width: 300px;
        border-radius: 1.2em;
        padding: 20px;
        background-color: white;
        @include shadow(0px, 3px, 15px, 0px, rgba(0, 0, 0, 0.1));

        .ionicon {
            height: 50px;
            width: 50px;
            color: $deepblue;
        }

        h2 {
            margin: 0;
            font-size: 30px;
            font-family: "Montserrat";
            color: $deepblue;
        }
    }

    a.button,
    button {
        transition: all 0.2s ease;
        display: inline-block;
        margin: 16px 0;
        border: 0;
        border-radius: 50vw;
        padding: 12px 24px;
        min-width: 100px;
        color: white;
        text-align: center;
        background-color: $deepblue;
        cursor: pointer;

        &:hover {
            background-color: #07baec;
            @include shadow(3px, 3px, 10px, 0px, rgba(0, 0, 0, 0.2));
        }
    }

    @media (max-width: $reactiveWidth) {
        div.paragraph {
            margin: 0 0 20px 0;
        }
    }
}

/* APPUNTAMENTI */

#appointmentsGrid {
    display: grid;
    grid-template-columns: 30fr 70fr;
    grid-template-rows: auto minmax(600px, 1fr);
    margin-bottom: 0;
    min-height: 600px;
}

#errorContainer {
    grid-column: 1 / 3;

    div {
        background-color: #a004;
        margin: 0 20px 30px;
        padding: 10px;
        border: 2px solid #c00;
        border-radius: 0.5em;
    }

    p {
        display: block;
        margin: auto;
        text-align: center;
    }
}

#appointmentTime {
    display: grid;
    grid-template-rows: auto auto 1fr;

    .legend {
        padding: 8px;
    }
    .legend > div {
        display: flex;
        align-items: center;
        color: #444;
        font-size: 16px;
        margin: 0;
    }
    .dayContainer {
        /* ( ͡° ͜ʖ ͡°) */
        min-height: 540px;
        height: auto !important;
        padding: 0 !important;
    }
}

.colorSquare {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: 0 10px 0 10px;
    border-radius: 3px;
}

.arrowContainer {
    cursor: pointer;
    display: flex;
    align-items: center;
    &.flex-right {
        margin-left: 0;
    }
    &.unselectable {
        color: #999;
    }
    .ionicon {
        height: 100%;
        font-size: 40px;
    }
}

.weekdaySelector {
    display: flex;
    @include noselect();
}

.appointmentsContainer {
    margin: 0px 30px;
    border-radius: 0.6em;

    div {
        position: relative;
        display: flex;
        z-index: 0;
        box-sizing: border-box;
        height: 25px;
        margin: 2.5px 0px 5px;
        border-radius: 0.6em;
        background-color: $deepblue;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        justify-content: center;
        &.unselectable {
            background-color: #999;
        }
        &:hover:not(.unselectable):not(.selected) {
            z-index: 1;
            @include shadow(0px, 0px, 20px, 3px, rgba(0, 0, 0, 0.3));
        }
        &.selected {
            z-index: 1;
            @include shadow(0px, 0px, 20px, 3px, #07baec56);
            background-color: #07baec;
        }

        p {
            font-family: "Montserrat";
            text-align: center;
            color: white;
            margin: auto;
        }
    }
}

#appointmentForm {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    align-items: stretch;

    input {
        &:not([type="checkbox"]) {
            transition: all 0.2s ease-in-out;
            margin: 10px 20px;
            border: 0;
            border-radius: 6px;
            height: 30px;
            font-size: 22px;
            padding: 5px;
        }
        &:hover,
        &:focus {
            outline: none;
            @include shadow(0px, 3px, 20px, 0px, rgba(0, 0, 0, 0.1));
        }
    }

    h1,
    h2 {
        font-family: "Montserrat";
        margin: 10px 20px;
    }

    p {
        margin: 20px;
        text-align: center;
    }

    button {
        padding: 10px 25px;
        font-family: "Montserrat";
        font-size: 20px;
        font-weight: bold;
    }
}

.checkboxContainer {
    position: relative;
    display: flex;
    margin: 10px 20px;
    min-height: 35px;
    align-items: center;
    @include noselect();

    input[type="checkbox"] {
        position: relative;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        -o-appearance: none;
        appearance: none;
        height: 20px;
        min-width: 20px; // non so perché senza minwidth la larghezza va a 14
        width: 20px;
        margin-right: 10px;
        border-radius: 4px;
        background-color: #cccccc;
        overflow: hidden;
        cursor: pointer;
        &::before {
            position: absolute;
            content: url("./assets/checkmark.svg");
            transition: all 0.2s ease-in-out;
            opacity: 0;
            top: -25%;
            left: -25%;
            height: 150%;
            width: 150%;
            display: block;
            background-color: #07baec;
        }
        &:checked::before {
            opacity: 1;
        }
    }
}

.nameContainer {
    display: flex;

    input {
        width: 100%;
    }

    .prefix {
        display: inline-flex;
        align-items: center;
        font-size: 20px;
        font-weight: bold;
        margin-left: 20px;
        margin-right: -10px;
    }
}

@media (max-width: $reactiveWidth) {
    #appointmentsGrid {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        gap: 50px;
    }
    #appointmentForm {
        padding: 0;
    }
    #errorContainer {
        grid-column: 1;
    }
}

#loadingShader {
    z-index: 1;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #44444444;
}

.successScreen {
    text-align: center;
    padding: 0 10%;

    span {
        color: $deepblue;
    }
}

/* CALENDARI */

#hoursSelect {
    font-family: "Montserrat";
    width: 100%;
    font-size: 25px;
    font-weight: bold;
    border: 0;
    border-radius: 10px;
    padding: 10px;
    transition: all 0.2s ease;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath d='M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 10px top 50%;
    background-size: 35px;
    cursor: pointer;
    &:hover,
    &:focus {
        outline: none;
        @include shadow(0px, 3px, 20px, 0px, rgba(0, 0, 0, 0.1));
    }
}

.calendar {
    padding-left: 15px;
    display: grid;
    width: auto;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: auto 460px auto;
}

.weekday {
    margin: 20px auto;
}

.dayContainer {
    padding: 10px;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 100%;
}

.separatorLine {
    position: absolute;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);

    span {
        color: rgba(0, 0, 0, 0.4);
        height: 16px;
        line-height: 16px;
        font-size: 16px;
        position: absolute;
        width: 50px;
        left: -60px;
        top: -8px;
        text-align: right;
        @include noselect();
    }
}

.shift {
    position: absolute;
    box-sizing: border-box;
    left: 10px;
    right: 10px;
    padding: 4px 8px;
    background-color: $deepblue;
    border-radius: 0.6em;
    @include shadow(0px, 3px, 20px, 0px, rgba(0, 0, 0, 0.2));

    h2,
    h3 {
        font-family: "Montserrat";
        color: white;
        margin: 0;
    }
}

.calendarDescription {
    grid-column: 1 / 7;
    padding: 10px;

    ol,
    ul {
        font-weight: normal;
        font-size: 20px;
    }
}

@media (max-width: $reactiveWidth) {
    #hoursSelect {
        font-size: 20px;
    }
    .separatorLine span {
        top: 0px;
        left: 0px;
        text-align: left;
    }
    .shift {
        right: 2px;
        left: 2px;
        h3 {
            font-size: 15px;
            line-height: 13px;
            text-align: center;
        }
    }
}

/* CONTATTI */

h1,
h2 {
    font-family: "Montserrat";
}

.contacttable {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    background-color: white;
    padding: 0 20px;
    margin-top: 4px;
    h2 {
        display: inline;
    }
}

a.button h2 {
    margin: 0;
}

.mapouter {
    background-color: white;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    text-align: right;
    height: 400px;
    width: 100%;
}

.gmap_canvas {
    overflow: hidden;
    background: none !important;
    height: 100%;
    width: 100%;
}

.gmap_canvas > iframe {
    width: 100%;
    height: 100%;
}

/* REGOLAMENTO */

ol {
    font-size: 1.4em;
    font-weight: bold;
    p {
        font-size: 1em;
        font-weight: normal;
    }
}

/* RESERVED */

#reserved {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto minmax(200px, auto) minmax(470, auto);
    grid-template-areas: "error error" "current next" "info selector";
    gap: 10px;

    .errorContainer {
        grid-area: error;
    }

    .currentcard {
        background-color: $deepblue;
        color: white;
        width: calc(100% - 20px);
        min-height: 200px;
        max-width: 380px;
        margin: 10px auto;
        padding: 10px;
        border-radius: 12px;
        @include shadow(0px, 0px, 15px, 5px, rgba(0, 0, 0, 0.2));

        p {
            margin: 0;
        }

        h2 {
            margin: 10px 0;
        }
    }

    .current {
        grid-area: current;
    }

    .next {
        grid-area: next;
    }

    .selector {
        grid-area: selector;
        margin: 0 auto;
        width: 300px;
        // overflow: hidden;
        max-height: 100%;

        .dayContainer {
            height: 400px;
            padding-top: 0;
            overflow: hidden scroll;
        }
    }

    .info {
        position: relative;
        grid-area: info;
        padding: 0 20px;
        overflow: auto;

        p:first-child {
            font-size: 1em;
            font-weight: bold;
            color: #555555;
        }

        svg,
        .save {
            display: block;
        }

        .save {
            width: 40px;
            height: 40px;
            -webkit-mask: url("./assets/checkmark.svg") no-repeat center;
            mask: url("./assets/checkmark.svg") no-repeat center;
            mask-size: 150%;
            background-color: green;
        }

        & > svg,
        .svg {
            cursor: pointer;
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }

    @media (max-width: $reactiveWidth) {
        grid-template-columns: 1fr;
        grid-template-rows: auto minmax(200px, auto) minmax(200px, auto) minmax(470, auto) auto;
        grid-template-areas: "error" "current" "next" "selector" "info";
    }
}
